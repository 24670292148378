import menu from './modules/menu';
import { swiper } from './slider';
import lazyImg from './modules/lazy-img';
import { toggleActiveAccordion } from './modules/accordeon';

window.addEventListener('DOMContentLoaded', () => {
  menu();
  lazyImg();
  lightGallery(document.getElementById('gallery'), {
    download: false,
    share: false,
    selector: '.diplomas__item',
  });
  lightGallery(document.getElementById('appointment'), {
    download: false,
    share: false,
    selector: '.button-md',
  });
  toggleActiveAccordion();
  swiper;

  document.querySelectorAll('a[href^="#"').forEach((link) => {
    link.addEventListener('click', function (e) {
      e.preventDefault();
      let href = this.getAttribute('href').substring(1);

      const scrollTarget = document.getElementById(href);
      console.log(scrollTarget);
      const topOffset = 54;

      const elementPosition = scrollTarget.getBoundingClientRect().top;
      const offsetPosition = elementPosition - topOffset;

      window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth',
      });
    });
  });
});
