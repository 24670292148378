
const menu = () => {
    function openMenu() {
  let menu =  document.querySelector('.nav-toggle')
  let navigation = document.querySelector('.navigation')
  menu.addEventListener('click', () => {
      menu.classList.toggle('close-nav');
      navigation.classList.toggle('open')
  })
}
openMenu()
}

export default menu