import lozad from 'lozad'

const lazyImg = () => {
    function lazyGallery() {
        // const mainGallery = document.querySelectorAll('.lozad');
        // const observer = lozad(mainGallery); 
        // observer.observe();

        const observer = lozad('.lozad', {
            rootMargin: '10px 0px', // syntax similar to that of CSS Margin
            threshold: 0.1, // ratio of element convergence
            enableAutoReload: true // it will reload the new image when validating attributes changes
        });
        observer.observe();
        }
    lazyGallery()
}

export default lazyImg